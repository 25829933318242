<template>
  <module
      ref="module"
      id="disconnects"
      titleIcon="fa fa-plug"
      :title="$t('disconnects')"
      :use-default-list="false"
    >
    <div slot="global">
      <!-- In progress -->
      <div v-if="inprogress" class="text-center">
        <h2>{{ inprogresstxt }}</h2>
        <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda;"></i>
      </div>

      <div v-else class="row">

        <!-- Modal for Confirm Removal -->
        <modal :show.sync="modalconfirm" headerClasses="justify-content-center">
          <h4 slot="header" class="title title-up">{{ $t('deletes') }} {{ $t('completedtxt') }}?</h4>

          <div class="row">
            <div class="col-12">
              {{ $t('confirmtaskdone') }}
            </div>
          </div>

          <template slot="footer">
            <div class="left-side">
              <p-button @click.prevent="confirmedTaskDone" link>{{ $t('yestxt') }}</p-button>
            </div>
            <div class="divider"></div>
            <div class="right-side">
              <p-button type="danger" v-on:click="modalconfirm = false" link>{{ $t('notxt') }}</p-button>
            </div>
          </template>
        </modal>
        <!-- End Modal -->

        <div class="col-12 text-center" v-if="alert_me">
          <div :class="alert_class" class="ml-2 mb-2" style="display: block;font-weight: bold;">
            {{ alert_msg }}
          </div>
        </div>

        <div class="col-md-12">

          <div class="row">

            <div class="col-sm-2">
              <label> {{ sortbyTxt }} </label>
              <el-select v-model="sortby.column" :placeholder="sortbyTxt"
                         v-on:change="sortByCol">
                <el-option class="select-default"
                           v-for="item in sortby.columns"
                           :key="item.prop" :label="item.label" :value="item.prop">
                </el-option>
              </el-select>
            </div>

            <div class="col-sm-2">
              <label> {{ perpageTxt }} </label>
              <el-select
                v-model="pagination.perPage" :placeholder="perpageTxt">
                <el-option class="select-default"
                           v-for="item in pagination.perPageOptions"
                           :key="item" :label="item" :value="item">
                </el-option>
              </el-select>
            </div>

            <div class="col-sm-2">
              <label> {{ sortbyOrder }} </label>
              <el-select
                v-model="sortby.order" :placeholder="sortbyOrder"
                v-on:change="sortByCol">
                <el-option class="select-default"
                           v-for="item in sortby.orders"
                           :key="item.prop" :label="item.label" :value="item.prop">
                </el-option>
              </el-select>
            </div>


            <div class="col-sm-6">
              <div class="pull-right">
                <label> {{ searchtxt }}</label>
                <fg-input class="input-sm"
                          v-model="searchQuery"
                          addon-right-icon="nc-icon nc-zoom-split">
                </fg-input>
              </div>
            </div>
            <div class="col-sm-12 mt-2">
              <el-table class="table-striped"
                        :data="queriedData"
                        border
                        style="width: 100%">

                <el-table-column :label="actionlbl" min-width="250" width="250">
                  <div slot-scope="scope" class="text-center">
                    <div class="btn-group">
                      <el-tooltip :content="`${$t('copy_pass')}`" placement="top">
                        <p-button type="danger" link v-on:click="copyPass(`${scope.row.acc_id}`)"
                                  v-if="!scope.row.showingpass">
                          <i class="fa fa-key" style="font-size: x-large;"></i>
                        </p-button>
                      </el-tooltip>

                      <p-button type="success" link v-if="scope.row.showingpass">
                        <i class="fa fa-key" style="font-size: x-large;"></i>
                      </p-button>

                      <p-button type="primary" round icon link v-if="scope.row.follower == 'master'">M</p-button>
                      <p-button type="info" round icon link v-if="scope.row.follower !== 'master'">F</p-button>

                      <p-button link v-on:click="sendDisconnectedMail(scope.row)">
                        <i class="fa fa-envelope" style="font-size: x-large;"></i>
                      </p-button>
                    </div>
                  </div>
                </el-table-column>

                <el-table-column :min-width="140"
                                 :label="`${this.$t('userid')}`">
                  <template slot-scope="scope">
                    {{ scope.row.userid }}
                  </template>
                </el-table-column>

                <el-table-column
                  :label="`${this.$t('account')}`">
                  <template slot-scope="scope">
                <span v-on:click="copyit(scope.row.account)" class="clickable">
                  {{ scope.row.account }} ({{ scope.row.platform.toUpperCase() }})
                </span>
                  </template>
                </el-table-column>

                <el-table-column
                  :label="`${this.$t('strategytxt')}`">
                  <template slot-scope="scope">
                    {{ scope.row.strat_name }}
                  </template>
                </el-table-column>

                <el-table-column
                  :label="`${this.$t('mattxt')}`">
                  <template slot-scope="scope">
                    {{ scope.row.mat_server }}
                  </template>
                </el-table-column>

                <el-table-column
                  :label="`${this.$t('servertxt')}`">
                  <template slot-scope="scope">
                <span v-on:click="copyit(scope.row.server)" class="clickable">
                  {{ scope.row.server }}
                </span>
                  </template>
                </el-table-column>

                <el-table-column
                  :label="`${this.$t('date')}`">
                  <template slot-scope="scope">
                    {{ scope.row.last_update }}
                  </template>
                </el-table-column>

                <el-table-column
                  :label="`${this.$t('secondssince')}`">
                  <template slot-scope="scope">
                    {{ scope.row.since_last_update }}
                  </template>
                </el-table-column>


              </el-table>

              <input id="clipit" type="text" v-model="clipboard" style="display: none">
            </div>
            <div class="col-sm-6 pagination-info">
              <p class="category">Showing {{ from + 1 }} to {{ to }} of {{ total }} entries</p>
            </div>
            <div class="col-sm-6">
              <p-pagination class="pull-right"
                            v-model="pagination.currentPage"
                            :per-page="pagination.perPage"
                            :total="pagination.total">
              </p-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </module>

</template>
<script>
  import Vue from 'vue'
  import {Table, TableColumn, Select, Option} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import {Button, Modal} from 'src/components/UIComponents'
  import {Tooltip} from 'element-ui'
  import swal from "sweetalert2";
  import {i18n} from "@/assets/i18n-plugin";

  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  Vue.use(require('vue-moment'))

  const user_data = JSON.parse(localStorage.getItem('user-info'));

  let bills = [];
  let timeoutcount = 5000;
  let refreshtimeout = 60000;

  export default {
    components: {
      PPagination, [Button.name]: Button, Modal,
      [Tooltip.name]: Tooltip
    },
    computed: {
      pagedData () {
        return this.tableData.slice(this.from, this.to)
      },
      /***
       * Searches through table data and returns a paginated array.
       * Note that this should not be used for table with a lot of data as it might be slow!
       * Do the search and the pagination on the server and display the data retrieved from server instead.
       * @returns {computed.pagedData}
       */
      queriedData () {
        if (!this.searchQuery) {
          this.pagination.total = this.tableData.length
          return this.pagedData
        }
        let result = this.tableData
          .filter((row) => {
            let isIncluded = false
            for (let key of this.propsToSearch) {
              //let rowValue = row[key].toString()
              let searchRegex = new RegExp(this.searchQuery, 'gi');
              //if (rowValue.includes && rowValue.includes(this.searchQuery.toLowerCase())) {
              if (row[key].toString().match(searchRegex) !== null) {
                isIncluded = true
              }
            }
            return isIncluded
          })
        this.pagination.total = result.length
        return result.slice(this.from, this.to)
      },
      to () {
        let highBound = this.from + this.pagination.perPage
        if (this.total < highBound) {
          highBound = this.total
        }
        return highBound
      },
      from () {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },
      total () {
        this.pagination.total = this.tableData.length
        return this.tableData.length
      }
    },
    data () {
      return {
        perpageTxt: this.$t('perpage'),
        sortbyTxt: this.$t('sortby'),
        sortbyOrder: this.$t('sortbyorder'),
        searchtxt: this.$t('searchtxt'),
        colSort: 'last_update',
        inprogress: true,
        actionlbl: this.$t('action'),
        alert_me: false,
        alert_msg: null,
        inprogresstxt: this.$t('loadingtxt') +" "+ this.$t('disconnects') +". " + this.$t('pleasewait'),
        alert_class: 'text-danger',
        sortby: {
          column: 'mat_server',
          order: 'asc',
          orders: [
            {
              prop: 'asc',
              label: this.$t('ascending')
            },
            {
              prop: 'desc',
              label: this.$t('descending')
            }
          ],
          columns: [
            {
              prop: 'account',
              label: this.$t('account')
            },
            {
              prop: 'strat_name',
              label: this.$t('strategytxt')
            },
            {
              prop: 'mat_server',
              label: this.$t('mattxt')
            },
            {
              prop: 'server',
              label: this.$t('servertxt')
            },
            {
              prop: 'last_update',
              label: this.$t('date')
            },
            {
              prop: 'since_last_update',
              label: this.$t('secondssince')
            }
          ]
        },
        pagination: {
          perPage: 50,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50],
          total: 0
        },
        searchQuery: '',
        propsToSearch: ['account', 'mat_server', 'server', 'strat_name'],
        tableColumns: [
          {
            prop: 'strat_name',
            label: this.$t('strategytxt')
          },
          {
            prop: 'mat_server',
            label: this.$t('mattxt')
          },
          {
            prop: 'server',
            label: this.$t('servertxt')
          },
          {
            prop: 'last_update',
            label: this.$t('date')
          },
          {
            prop: 'since_last_update',
            label: this.$t('secondssince')
          },
        ],
        tableData: [],
        modalconfirm: false,
        passid: null,
        did: null,
        interval: null,
        aidpwd: {},
        clipboard: '',
      }
    },
    methods: {
      copyit(value) {
        let cb = document.getElementById("clipit");
        cb.style.display = 'block';
        cb.value = value;
        cb.select();
        document.execCommand('copy');
        cb.style.display = 'none';
        this.$toast.success(`${value} Copied!`)
      },
      closeModalAlert() {
        this.modalconfirm = false;
        this.did = null;
      },
      showpass(aid) {
        const el = document.createElement('textarea');
        el.value = this.aidpwd[aid];
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);

        let ridx = this.tableData.findIndex(t => t.acc_id == aid);
        if (ridx >= 0) {
          this.tableData[ridx].showingpass = true;
          const _resetLock = a => {
            this.tableData[ridx].showingpass = false;
          };
          setTimeout(_resetLock, timeoutcount);
        }
      },
      sendDisconnectedMail(row) {
        let param = {
          userid: row.userid,
          type: row.follower,
          account: row.account,
          strat_name: row.strat_name
        }

        let successCallback = resp => {
          Vue.$toast.success(this.$t('SUCCESS_DONE'));
        }
        let failCallback = resp => {
          Vue.$toast.error(this.$t('rdpdownmsg'));
        }

        swal({
          title: this.$t('needconfirmation'),
          text: this.$t('disconnect_mail_notification'),
          type: 'warning',
          showCancelButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false
        })
          .then(() => {
            this.$sendDisconnectedEmail(param)
              .then(successCallback)
              .catch(failCallback);
          })
          .catch(swal.noop);
      },
      copyPass(aid) {
        if (!this.aidpwd[aid]) {
          const _showpass = r => {
            if (!r.success) {
              this.$toast.warn(r.msg)
              return;
            }
            this.aidpwd[aid] = r.data.password;
            this.showpass(aid);
          };
          this.$getAccountDetails(aid)
            .then(_showpass, this.failop);
        } else {
          this.showpass(aid);
        }
      },
      completedTask() {
        this.closeModalAlert();

        this.alert_me = true;
        this.alert_msg = this.$t('goodjob');
        this.alert_class = 'text-success';
        // Reload and close
        this.$getDeletes().then(this.loadIt, this.failop);
        setTimeout(this.resetAlert, timeoutcount);
      },
      confirmedTaskDone() {
        this.$deletesDone(this.did, user_data.email).then(this.completedTask, this.failop);
      },
      done(id) {
        this.did = id;
        this.modalconfirm = true;
      },
      resetAlert() {
        this.alert_me = false;
        this.alert_msg = null;
        this.alert_class = 'text-danger';
      },
      loadData() {
        this.$getDisconnects().then(this.loadIt, this.failop);
      },
      loadIt(resp) {
        this.tableData = [];
        for(var i=0;i < resp.data.length;i++) {
          let s = resp.data[i];
          s['showingpass'] = false;

          this.tableData.push(s);
        }
        this.sortByCol();

        if (this.interval === null) {
          this.interval = setInterval(this.loadData, refreshtimeout);
        }

        this.inprogress = false;
      },
      sortItNum (a, b) {
        if (this.sortby.order == 'asc') {
          return a[this.colSort] - b[this.colSort];
        }
        return b[this.colSort] - a[this.colSort];
      },
      sortIt (a, b) {
        if (this.sortby.order == 'asc') {
          if (a[this.colSort] < b[this.colSort])
            return -1;
          if (a[this.colSort] > b[this.colSort])
            return 1;
        }
        if (b[this.colSort] < a[this.colSort])
          return -1;
        if (b[this.colSort] > a[this.colSort])
          return 1;
        return 0;
      },
      sortByCol() {
        this.colSort = this.sortby.column;
        if (this.sortby.column !== 'since_last_update') {
          this.tableData.sort(this.sortIt);
        }
        else {
          this.tableData.sort(this.sortItNum);
        }
      },
      failop(error) {
        if (error.data && error.data.msg) {
          error = error.data.msg;
        }
        this.inprogress = false
        this.$toast.error(this.$t(error))
      }
    },
    destroyed() {
      clearInterval(this.interval);
    },
    mounted () {
      this.loadData();
    },
    created(){
      this.$setTranslatingInProgress()
    },
    beforeDestroy(){
      this.$root.$off("showTranslatingProgress")
    },
  }
</script>
<style>
</style>
